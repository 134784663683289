import React, {useState, useEffect} from 'react';
import { db } from './Database';
import './CommentSection.css';
import getFirebase from './Database.js'

export default function CommentSection(props) {
	
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [pageId, setPageId] = useState(props.page_id);
	const [timestamp, setTimestamp] = useState(Date.now());
	const [approved, setApproved] = useState('false');
	const [reply, setReply] = useState("");
	const [replyTimestamp, setReplyTimestamp] = useState("");
	const [replier, setReplier] = useState("");
	const [comments, setComments] = useState([]);
	
	const handleSubmit = (event) => {
		event.preventDefault();

		db.collection('comments').add({
			pageId: pageId,
			timestamp: timestamp,
			name: name,
			email: email,
			message: message,
			approved: approved,
			replyTimestamp: replyTimestamp,
			replier: replier,
			reply: reply,
		})
		.then(() => {
			alert('Thank you for sharing your comment. It will become visible once it is approved by the moderator');
		})
		.catch((error) => {
			alert(error.message);
		});

		setName('');
		setEmail('');
		setMessage('');
	};

  useEffect(() => {
  		const firebase = getFirebase();
  		
  		db.collection('comments')
  			.where("pageId", "==", props.page_id)
  			.where("approved", "==", 'true')
  			.orderBy("timestamp")
  			.onSnapshot(snapshot => {
  					const allComments = snapshot.docs.map(doc => doc.data());
      			setComments(allComments);
    		})
  }, [props.page_id]);

	return (
		<div className='comments_section_container'>
			<h2 className='comment_section_heading'>&nbsp;Comments</h2>
			<div className='comment_viewer'>
				<CommentsView 
					comments = {comments}
				/>
      </div>

      <div className='comment_form_container'>
        <form className='form' onSubmit={handleSubmit}>
        	<h2>Leave your comment</h2>

        	<label>Name *</label>
        	<input 
        		placeholder="name" 
        		value={name}
        		onChange={(event) => setName(event.target.value)}
        	/>
        	<label>Email * (email address will not be displayed on the website)</label>
        	<input 
        		placeholder="you@email.com" 
        		value={email}
        		onChange={(event) => setEmail(event.target.value)}
        	/>
        	<label>Message *</label>
        	<textarea 
        		placeholder="Message" 
        		value={message}
        		onChange={(event) => setMessage(event.target.value)}>
        	</textarea>
        	<button type='submit'>Submit</button>
        </form>
      </div>
      
    </div>
  );
}

function CommentsView(props) {
	if (typeof props.comments == 'undefined' || props.comments.length == 0) { 
		return (<div> </div>);
	} else {
		const listItems = props.comments.map((comment, index) => {
			
			var comment_date=new Date(comment.timestamp);
			var comment_display_date=comment_date.toDateString()
							+' at '+((comment_date.getHours() % 12) || 12)
							+':'+(comment_date.getMinutes() > 9 ? comment_date.getMinutes() : '0'+comment_date.getMinutes())
							+' '+(comment_date.getHours() < 12 ? "AM" : "PM");

			var reply_date=new Date(comment.replyTimestamp);
			var reply_display_date=reply_date.toDateString()
							+' at '+((reply_date.getHours() % 12) || 12)
							+':'+(reply_date.getMinutes() > 9 ? reply_date.getMinutes() : '0'+reply_date.getMinutes())
							+' '+(reply_date.getHours() < 12 ? "AM" : "PM");

      if (comment.reply == "") {
        return (
          <div key={index} className="comment_text">
            <h4 className='commenter'>{comment.name}</h4>
            <h4 className='comment_date'>{comment_display_date}</h4>
            <p className='comment'>{comment.message}</p>
          </div>		
        )
      } else {
        return (
          <div key={index} className="comment_text">
            <h4 className='commenter'>{comment.name}</h4>
            <h4 className='comment_date'>{comment_display_date}</h4>
            <p className='comment'>{comment.message}</p>
            <h4 className='replier'>{comment.replier}</h4>
            <h4 className='reply_date'>{reply_display_date}</h4>
            <p className='reply'>{comment.reply}</p>
          </div>		
        )
      }
  	})

  	return (
      <div>
      	{listItems }
      </div>
    );
	}
}

/*var docRef = db.collection("comments").doc("2JCfe7XID9ES8BHYwf32");

				docRef.get().then((doc) => {
    			if (doc.exists) {
        		console.log("Document data:", doc.data());
    			} else {
        		// doc.data() will be undefined in this case
        		console.log("No such document!");
    			}
				}).catch((error) => {
    			console.log("Error getting document:", error);
				});*/

				/*db.collection("comments").where("pageId", "==", props.page_id)
    			.get()
    			.then((querySnapshot) => {
        		querySnapshot.forEach((doc) => {
            	// doc.data() is never undefined for query doc snapshots
            	console.log(doc.id, " => ", doc.data());
        		});
    		})
    		.catch((error) => {
        	console.log("Error getting documents: ", error);
    		});*/

    		/*db.collection("comments").where("pageId", "==", props.page_id)
    			.get()
    			.then((querySnapshot) => {
        		console.log(querySnapshot.docs.map(doc => doc.data()));
        		setComments(querySnapshot.docs.map(doc => doc.data()));

							setComments({
      					[comments]: [...comments, 'Comentário']
    					})

    		})
    		.catch((error) => {
        	console.log("Error getting documents: ", error);
    		});

    		console.log(comments);
    		setAbc("ho-gaya");
    		console.log("ab toh" + abc);*/