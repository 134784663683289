import React from 'react';
import { Link } from 'gatsby'
import Article from '../../components/Article'
import "../../components/App.css"
import "../../components/ArticleContent.css"

export default function BlogFaceAcne() {
	
	return (
		<Article
			title='My experience of curing my acne'
			takeaway_title='Take following steps to reduce inflammation & strengthen immunity (to cure acne):'
			page_id='Blog - Face Acne'
			read_time='6 mins'
			author='Brij Bhushan'
			image1='/face-before-after.jpg'
			image1_description='Once the acne stopped, the marks disappeared in few months'
			image2='/brij-usa.jpg'
			image2_description='This pic from the trip captures the acne eruption that I observed'
			toggle_article='Solution'
			toggle_url='/article/soln_faceacne'
			related_article='Solution - Face Acne'
			takeaway_data={TakeawayData}
			related_articles={RelatedArticles}
		>
			<div className='article_container'>
					<p className='article_para'>
						I have had acne on my chest & back since I was 15 yrs old. I had learnt to manage them with the use of antibacterial talc powder. However, once I turned 35 yrs old, I started having acne on my face as well. First I thought maybe it was because I have a tendency to scratch my face. Now, I started controlling my hands & stopped touching my face completely but acne on my face didn't stop coming up. It continued for several months and eventually I became used to witnessing 3 new acne on my face every morning. By this time, my face was completely full of acne and its scars.
					</p>
					<p className='article_para'>
						Then I consulted a dermatologist in a leading hospital in my town. She first gave me an oral antibiotic tablet along with topical acne treatment. That controlled the outbreak. After 2-3 months of that treatment I was recommended chemical peeling to get rid of acne marks, which I went through. However, sooner or later there used to be new outbreaks & again the same treatment was repeated. After 6 months, I realized that the treatment is just skin-deep but the problem is far more deep-rooted.
					</p>
					<p className='article_para'>
						Next, I reached out to a leading homeopath in the town. He examined my skin, then criticized the western treatment that I had taken so far and use of harsh chemicals on my skin. Then, he said the problem has built up over a period of several years and for a permanent solution, I will have to take his treatment for a long time. I was convinced with his confidence in the treatment and continued his medicine for 3 yrs. Again, my acne used to reduce for some time but followed by another outbreak. The only difference was that this time I took 3 years to understand that this treatment is also not heading anywhere like the previous one.
					</p>
					<p className='article_para'>
						By that time, I had started researching health related topics as I have shared in the other blog. So, I decided to also research about the acne problem in different medical journals, a few books and ancient ayurveda texts. The answer was simple but almost orthogonal to my treatment so far. While the treatments that I had received so far were focused on fixing the problem on my skin, the root of the problem was in my diet & lifestyle which was increasing inflammation in my body and reducing my immunity to fight the infection. Based on my research, I made the following 2 major changes in my diet:
						<ol className='article_list'>
							<li>Correct the way you consume milk</li>
    						<li>No refined white sugar</li>
    					</ol>
						(For explanation of each principle and details on their rationale & references, one should refer to my other article on the acne solution <Link to='/article/soln_faceacne'>here</Link>)
					</p>
					<p className='article_para'>
						Once I implemented these 2 rules in my diet, within a few months the acne disappeared from the face. Then I became bold enough to check the results on my chest & back as well. So, I stopped daily application of antibacterial talc powder on my chest & back (which I was using to control the acne). The results were as I expected. The acne didn’t appear on my chest & back even after I stopped my maintenance regime.
					</p>
					<p className='article_para'>
						Initially, I wondered how milk & sugar could be responsible for the acne problem because I have been consuming milk with breakfast since my childhood and sugar intake was also the same since those days. However, if you look from a different perspective, the problem started aggravating after I reached my mid-30s. That was a signal from the body that it cannot contain the ill-effects of my diet any further and once I identified the problem in my diet the acne eruptions on face, chest & back stopped for good.
					</p>
					<p className='article_para'>
						My confidence in the solution was emboldened when I made a couple of 2 week travels out of the town. My first trip was to the US and I observed that a few acne appeared on my face during the 2 weeks. When I analyzed the difference in my diet, then I realized that it was the variety of milk. In India, I drink Cow’s A2 milk, whereas in the US I was consuming Cow’s A1 milk.
					</p>
					<p className='article_para'>
						My next trip was after 3 months. It was again to the US for 2 weeks and again during the trip a few acne appeared on my face. After the trip, when I analyzed the difference in my diet, I realized that during the trip I had started to have 2 chocolate chip cookies from the hotel's vending machine once in a while (during hunger pangs). I used to think that having a little bit of sugar-based products once in a while won’t cause any harm.
					</p>
					<p className='article_para'>
						Anyway, after another 3 months, I had another trip to the US which was again of 2 weeks duration. This time, I made sure that I neither drink A1 milk nor eat any sugar-based products, and as I anticipated, I didn’t get any acne eruption during this trip.
					</p>
					<p className='article_para'>
						This way, with the 2 changes in my diet, I managed to solve the 8 yrs old problem of face acne and the 30 yrs old problem of acne on chest & back.
					</p>
			</div>
		</Article>
	);

}

const TakeawayData = [
	{
		takeaway: "If you drink milk, drink only Cow's A2 milk",
	},
	{
		takeaway: "Don't eat anything 2 hrs before & 2 hrs after having milk",
	},
	{
		takeaway: "Don't drink more than 1 glass of milk in a day",
	},
	{
		takeaway: "Don't eat food that has milk or cream along with salt",
	},
	{
		takeaway: "Don't consume sugar & products that contain sugar",
	},
];

const RelatedArticles = [
	{
		title: 'Solution - Face Acne',
		path: '/article/soln_faceacne',
	},
];