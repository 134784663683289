import React from 'react';
import { Link } from 'gatsby';
import Helmet from "react-helmet"
import App from "./App"
import CommentSection from './CommentSection';
import LikeSection from './LikeSection';

import './Article.css';

export default function Article(props) {

	return (
		<App>

      		<Helmet>
        		<title>{props.title} | CoDreams Health</title>
        		<meta name="description" content="..." />
        		<meta name="keywords" content="objective, why, workflow" />
        		<meta property="og:title" content={props.title} />
        		<meta property="og:type" content="website" />
        		<meta property="og:description" content="..." />
        		<meta property="og:image" content="" />
        		<meta property="og:locale" content="en_US" />
        		<meta property="og:url" content="https://codreams.org" />
        		<link rel="canonical" href="https://codreams.org" />
      		</Helmet>

      		<div className='right-frame'>

				<div className='article_page'>
					<div className='article_card'>
      					<div className="article_text_Wrapper">
      						<span className='breadcrumbs'><Link to='/'>Home</Link> : {props.page_id}</span>
        					<h1 className="article_title">{props.title}</h1>
							<h3>Reading time: {props.read_time}</h3>
							&nbsp;
        					{props.children}
        				</div>
        				<LikeSection page_id={props.page_id}/>
        				<CommentSection page_id={props.page_id}/>

					</div>
					<div className='right_column_wrapper'>
						<div className='article_card'>
        					<div className='artcard__body'>
          						<h2 className='artcard__title'>Author </h2>
          						<h3 className='artcard__title'>{props.author}</h3>
            					<button className='artcard__btn'>
              						<Link to={props.toggle_url}>
                						Goto {props.toggle_article}
              						</Link>
            					</button>
        					</div>
      					</div>
      					<div className='article_card'>
        					<img src={props.image1} className='artcard__img' alt=""></img>
        					<div className='artcard__desc_wrapper'>
          						<p className='artcard__description'>{props.image1_description}</p>
        					</div>
      					</div>
      					<div className='article_card'>
        					<div className='artcard__body'>
          						<h2 className='artcard__title'>Key Takeaway</h2>
          						<h3 className='artcard__takeaway_title'>{props.takeaway_title}</h3>
								<p>
    								<ul className='artcard_list'>	
                						{props.takeaway_data.map((item, index) => {
                  							return (
                    							<li key={index}>
                        							<span>{item.takeaway}</span>
                    							</li>
                  							)
                						})}
              						</ul>
								</p>
            					<button className='artcard__btn'>
              						<Link to='/'>
                						.
              						</Link>
            					</button>
        					</div>
      					</div>
      					<div className='article_card'>
        					<img src={props.image2} className='artcard__img' alt=""></img>
        					<div className='artcard__desc_wrapper'>
          						<p className='artcard__description'>{props.image2_description}</p>
        					</div>
      					</div>
      					<div className='article_card'>
        					<div className='artcard__body'>
          						<h2 className='artcard__title'>Related articles</h2>
          						<p className="singlePostDesc">
									<ul className='artcard_list'>
										{props.related_articles.map((item, index) => {
                  							return (
                    							<li key={index}>
                      								<Link to={item.path}>
                        								<span>{item.title}</span>
                      								</Link>
                    							</li>
                  							)
                						})}
    								</ul>
								</p>
        					</div>
      					</div>
					</div>
				</div>
			</div>

		</App>
	);
}