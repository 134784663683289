import React, {useState, useEffect} from 'react';
import { db } from './Database';
import getFirebase from './Database.js'
import './LikeSection.css';

export default function LikeSection(props) {

	const [listOfLikes,setListOfLikes] = useState([]);
	const [id,setId] = useState("");

  	useEffect(() => {
  		const firebase = getFirebase();
    	return db.collection('likes')
    		.where("pageId", "==", props.page_id)
    		.onSnapshot(querySnapshot => {
      		const list = [];
      		querySnapshot.forEach(doc => {
      			const { pageId, numOfLikes } = doc.data();
        		list.push({
          			id: doc.id,
          			pageId,
          			numOfLikes,
        		});
        		setNumOfLikes(numOfLikes + 1);
      		});
      		if (list.length == 0) {
      			
				setNumOfLikes(0);
      		}
      		setListOfLikes(list);
      	});
  	}, [props.page_id]);
	
	const [pageId, setPageId] = useState(props.page_id);
	const [numOfLikes, setNumOfLikes] = useState("");

	const handleLike = (event) => {
		event.preventDefault();

		

      	if (numOfLikes == 0) {
      		db.collection('likes').add({
					pageId: props.page_id,
					numOfLikes: 1,
				})
				.then(() => {
					
				})
				.catch((error) => {
					alert(error.message);
				});
      	} else {
			db.collection('likes').doc(id).update({
				pageId: pageId,
				numOfLikes: numOfLikes,
			})
			.then(() => {
				alert('Thank you for the feedback');
			})
			.catch((error) => {
				alert(error.message);
			});
		}
	};

	if (numOfLikes == 0) {
		return (
		
			<div className='likes_section_container'>
        		<div className='comment_viewer'>
        			<div className="like_wrapper">
            					<form className='like_form' onSubmit={handleLike}>
            						<button 
            							className='like_button'
            							type='submit' 
            							id='0'
            							value='0'
            							onClick={(event) => setId(event.target.id)}
            						>
            							<img 
            								className='like_img' 
            								src='/like.png'
            								id='0'
            								onClick={(event) => setId(event.target.id)}
             							>
            							</img>
            						</button>
            						<h4 className='like_count'>(0 likes)</h4>
            					</form>
            		</div>
        		</div>
        	</div>
        )
	} else {
		return (
		<div className='likes_section_container'>
        	<div className='comment_viewer'>
        		{
        			listOfLikes.map((like, index) => {
          				return(
            				<div key={index} className="like_wrapper">
            					<form className='like_form' onSubmit={handleLike}>
            						<button 
            							className='like_button'
            							type='submit' 
            							id={like.id}
            							value={like.id} 
            							onClick={(event) => setId(event.target.id)}
            						>
            							<img 
            								className='like_img' 
            								src='/like.png'
            								id={like.id}
            								onClick={(event) => setId(event.target.id)}
             							>
            							</img>
            						</button>
            						<h4 className='like_count'>({like.numOfLikes} likes)</h4>
            					</form>
            				</div>
          				)
        			})
      			}
        	</div>
        </div>
    	)
    }
}


